<template>
  <header class="w-screen h-fit flex flex-col justify-between p-5 items-center">
    <span class="text-2xl" style="font-family: 'Pacifico', cursive">Edwards Detailing</span>
  </header>
  <div class="w-screen flex items-center flex-col">
    <span>Text: <a class="text-blue-500" href="sms:8583679601">858-367-9601</a></span>
    <span>Mail: <a class="text-blue-500" href="mailto: me@edwardsdetail.com">me@edwardsdetail.com</a></span>
  </div>
  <div class="w-screen flex items-center flex-col pt-5">
    <div class="bg-black px-4 py-2 rounded-md">
      <RouterLink to="/">
        <span class="text-white"> Back to home</span>
      </RouterLink>
    </div>
  </div>
  <footer class="bg-black w-screen h-fit p-5 text-white flex justify-between items-center absolute bottom-0">
    <span class="font-medium">Edwards Detailing</span>
    <div class="flex flex-col">
      <span class="flex items-center gap-1"><i class="bx bx-phone" style="color: #ffffff"></i> <a class="text-blue-500" href="sms:8583679601">858-367-9601</a></span>
      <span class="flex items-center gap-1"><i class="bx bx-envelope" style="color: #ffffff"></i> <a class="text-blue-500" href="mailto: me@edwardsdetail.com">me@edwardsdetail.com</a></span>
      <span class="flex items-center gap-1"><i class="bx bxl-instagram" style="color: #fcfcfc"></i> <a class="text-blue-500" href="https://instagram.com/EdwardsDetail"> @EdwardsDetail</a></span>
    </div>
  </footer>
</template>
