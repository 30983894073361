<template>
  <header class="w-screen h-fit flex justify-between p-5 items-center">
    <div>
      <span class="text-2xl" style="font-family: 'Pacifico', cursive">Edwards Detailing</span>
    </div>
    <div class="text-black font-medium">
      <router-link to="/book">
        <div class="border border-gray-800 bg-black rounded-md px-3 py-2">
          <span class="font-medium text-md text-white">Book Now</span>
        </div>
      </router-link>
    </div>
  </header>
  <div class="flex flex-col items-center w-screen pt-5 pb-5">
    <span class="text-5xl font-bold textshadow text-center"
      >Protect Your <br />
      <span class="text-[rgb(233,46,15)]">Investment</span> With A Detail Today</span
    >
    <img src="../../GT3.png" class="pt-10" />
  </div>
  <div class="bg-[rgb(218,218,218)] h-fit w-screen flex p-5 items-center justify-around gap-4">
    <div class="flex items-center gap-3">
      <i class="bx bxs-phone"></i>
      <div class="flex flex-col">
        <span class="text-gray-600 uppercase font-semibold text-xs">Text us now at:</span>
        <span class="text-black font-medium"><a class="text-blue-600 cursor-pointer" href="sms:8583679601">858-367-9601</a></span>
      </div>
    </div>
    <div class="flex items-center gap-3">
      <i class="bx bxs-map"></i>
      <div class="flex flex-col">
        <span class="text-gray-600 uppercase font-semibold text-xs">Our Location</span>
        <span class="text-black font-medium">Encinitas, CA</span>
      </div>
    </div>
  </div>
  <div class="w-screen flex items-center flex-col pt-5 pb-5">
    <span class="text-2xl font-semibold text-center drop-shadow-md">Maintain Your Ride with Our New <span class="">Monthly</span> and <span class="">Bi-Monthly</span> Plan</span>
    <span>Up to 45% savings</span>
    <section class="flex pt-2">
      <div class="flex items-center gap-6 rounded-md border border-black px-5 py-3">
        <span>Full Detail</span>
        <div class="flex flex-col">
          <span>Monthly: $75</span>
          <span>Bi-Monthly: $55</span>
        </div>
      </div>
    </section>
  </div>
  <div class="w-screen pt-5 pb-5 flex items-center flex-col bg-[rgb(218,218,218)]">
    <span class="text-2xl font-semibold text-center drop-shadow-md">Quality Detailing For Less</span>
    <span>Support a local business and book today</span>
    <section class="flex flex-col gap-2 pt-2">
      <div class="flex items-center justify-around gap-6 rounded-md border border-black px-5 py-3">
        <span>Full Detail</span>
        <div class="flex flex-col">
          <span>$95</span>
        </div>
      </div>
      <div class="flex items-center gap-6 justify-around rounded-md border border-black px-5 py-3">
        <span>Interior Detail</span>
        <div class="flex flex-col">
          <span>$65</span>
        </div>
      </div>
      <div class="flex items-center gap-6 justify-around rounded-md border border-black px-5 py-3">
        <span>Exterior Detail</span>
        <div class="flex flex-col">
          <span>$45</span>
        </div>
      </div>
    </section>
    <router-link to="/book">
      <div class="flex bg-black items-center gap-6 justify-around rounded-md px-5 py-3 mt-5">
        <span class="text-white font-medium text-2xl">Book Today</span>
      </div>
    </router-link>
  </div>
  <div class="w-screen flex items-center flex-col pt-5 pb-5">
    <span class="text-2xl font-semibold text-center drop-shadow-md">What's Included?</span>
    <section class="flex gap-3 flex-col">
      <div class="flex flex-col items-center">
        <span class="font-semibold">Full Detail - $95</span>
        <ul class="font-normal">
          <span>All of the below</span>
        </ul>
      </div>
      <div class="flex flex-col items-center">
        <span class="font-semibold">Exterior Detail - $45</span>
        <div class="flex flex-col text-center">
          <span>Exterior Contact Wash</span>
          <span>Spray Wax Applied</span>
          <span>Exterior Windows & Mirror Cleaned</span>
          <span>Exterior Trim Wiped & Dressed</span>
          <span>Wheel Cleaned and Protected</span>
          <span>Tires Cleaned and Dressed</span>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <span class="font-semibold">Interior Detail - $65</span>
        <div class="flex flex-col text-center">
          <span>Carpet & Seat Shampoo</span>
          <span>Comprehensive Interior Vacuum</span>
          <span>Interior Windows & Mirrors Cleaned</span>
          <span>Trim Wiped & Dressed</span>
          <span>Trunk Vacuum</span>
          <span>Steering Column & Dash Detail</span>
          <span>Door & Trunk Jamb Clean</span>
          <span>Leather Seat Condition</span>
        </div>
      </div>
    </section>
  </div>
  <div class="w-screen flex items-center flex-col pt-5 pb-5 bg-[rgb(218,218,218)]">
    <span class="text-2xl font-semibold text-center drop-shadow-md pb-2">Add-ons</span>
    <section class="flex flex-col gap-2">
      <div class="flex items-center gap-6 justify-around rounded-md border border-black px-5 py-3">
        <span>Headlight Restoration</span>
        <div class="flex flex-col">
          <span>$45</span>
        </div>
      </div>
      <div class="flex flex-col items-center justify-around rounded-md border border-black px-5 py-3">
        <div class="flex items-center gap-6 justify-around">
          <span>Large Truck/SUV/Extras Fee</span>
          <div class="flex flex-col">
            <span>$15*</span>
          </div>
        </div>
        <span class="text-sm">*May be charged extra upon arrival</span>
      </div>
    </section>
  </div>
  <div class="w-screen flex items-center flex-col pt-5 pb-5">
    <span class="text-2xl font-semibold text-center drop-shadow-md pb-2">Photos</span>
    <section class="overflow-x-scroll w-screen flex items-center flex-row gap-2">
      <img src="../../media/9.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/12.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/8.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/6.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/1.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/13.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/2.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/4.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/5.jpeg" class="rounded-md h-[40vh]" />

      <img src="../../media/7.jpeg" class="rounded-md h-[40vh]" />

      <img src="../../media/10.jpeg" class="rounded-md h-[40vh]" />
      <img src="../../media/11.jpeg" class="rounded-md h-[40vh]" />
    </section>
  </div>
  <footer class="bg-black w-screen h-fit p-5 text-white flex justify-between items-center">
    <span class="font-medium">Edwards Detailing</span>
    <div class="flex flex-col">
      <span class="flex items-center gap-1"><i class="bx bx-phone" style="color: #ffffff"></i> <a class="text-blue-500" href="sms:8583679601">858-367-9601</a></span>
      <span class="flex items-center gap-1"><i class="bx bx-envelope" style="color: #ffffff"></i> <a class="text-blue-500" href="mailto: me@edwardsdetail.com">me@edwardsdetail.com</a></span>
      <span class="flex items-center gap-1"><i class="bx bxl-instagram" style="color: #fcfcfc"></i> <a class="text-blue-500" href="https://instagram.com/EdwardsDetail"> @EdwardsDetail</a></span>
    </div>
  </footer>
</template>

<script lang="ts"></script>
